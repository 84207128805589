<template>
  <div
    class="relative w-full bg-white rounded-[12px] overflow-hidden shadow-sm"
  >
    <header class="w-full flex items-center justify-between px-5 py-5">
      <h3 class="text-primary font-[800]">Today's rate</h3>

      <button class="inline w-[16px] h-[18px]" @click="closeModal">
        <close-icon />
      </button>
    </header>

    <page-loader v-if="isLoading" mini />
    <error-component
      v-else-if="isError"
      message="Error fetching current fx rates"
    />

    <div v-else-if="data" class="w-full">
      <table class="w-full table text-sm">
        <caption class="sr-only">
          Currency Exchange Rates
        </caption>
        <thead>
          <tr class="font-medium bg-[#ECECEC33] text-primary/60">
            <th scope="col" class="text-left pl-5 pr-5 py-2">Currency Pair</th>
            <th scope="col" class="text-right px-5 py-2">Buy</th>
            <th scope="col" class="text-right pl-5 pr-5 py-2">Sell</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-for="rate in data"
            :key="rate.currencyPair"
            class="border-b border-primary/20 border-dashed last:border-0"
          >
            <td class="font-medium text-primary text-left pl-5 pr-5 py-5">
              {{ rate.currencyPair }}
            </td>
            <td class="text-primary/80 px-5 py-5 text-right">
              {{ rate.buyRate || "N/A" }}
            </td>
            <td class="text-primary/80 pl-5 pr-5 py-5 text-right">
              {{ rate.sellRate || "N/A" }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="mostRecentUpdateTime"
        class="bg-[#F9F9F9] text-primary/70 text-center text-xs font-medium py-4"
      >
        Last updated: {{ formatDateTime(mostRecentUpdateTime) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGetCurrentFxRates } from "@/data-access/fx-rates";
import { formatDateTime } from "@/helpers";
import { computed } from "vue";

defineProps<{
  closeModal: () => void;
}>();

const { data, isLoading, isError } = useGetCurrentFxRates();

const mostRecentUpdateTime = computed(() => {
  if (data.value && data.value.length > 0) {
    return data.value.reduce((acc, curr) => {
      return new Date(acc.createdDate) > new Date(curr.createdDate)
        ? acc
        : curr;
    }).createdDate;
  }
  return null;
});
</script>
